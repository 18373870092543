.with-caret{
    cursor: pointer;
}
.order{
    margin-left: 10px;
    display: inline-block;
}
.order .caret{
    color: #ada5a5;
    vertical-align: middle;
    border-top: 6px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}
.order .dropup .caret{
    color: #ada5a5;
    border-top: 0;
    border-bottom: 7px dashed;
}
.note-btn{
    position: relative;
    display: inline-block;
    padding: 0.5em;
    border-radius: 3px;
    outline: none;
    font-family: inherit;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.15384615;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    color: #376d95;
    background-color: #e3f1fb;
    border: 1px solid #7aa7c7;
    box-shadow: inset 0 1px 0 0 #ffffffb3;
}
.order .caret.active{
    color: #061642;
}
.table-nowrap th, .table-nowrap td{
    border-left: 0;
    border-right: 0;
}
td:hover{
    cursor: default;
}
.notes-wiew{
    background-color: rgba(25, 118, 210, 0.07);
    box-shadow: 2px 0 rgba(25 ,118 ,210, .7), 2px 0 rgba(25 ,118 ,210,.7);
    color: inherit;
    border-radius: 0.3em;
    word-break: break-word;
    display: block;
    overflow-x: auto;
    padding: 15px 20px;
    background: #F0F0F0;
    margin-bottom: 25px;
}

.excerpt-content {
    display: block;
    min-width: 150px;
    max-width: 400px;
    white-space: pre-line;
}
.error{
    display: block;
    color: #FF6B4E;
}
.folder-icon{
    font-size: 40px;
    color: #f7b84e;
}
.file-system .action-icon,
.p-folder{
    font-size: 18px;
}
.root-folder{
    font-size: 12px;
}
.file-icon{
    font-size: 18px;
    color: #6e6e6e;
}
.download-file{
    font-size: 12px;
    color: #6e6e6e;
}
.file-icon.excel{
    color: #34c38f
}
.file-icon.pdf{
    color: #FF6B4E;
}
.file-icon.image{
    color: #74ecff;
}
.file-icon.video{
    color: #13958c;
}
.css-2b097c-container {
    min-width: 200px;
}
.close-edition{
    cursor: pointer;
    margin-left: 5px;
    color: #13958c;
    font-size: 18px;
    border: 1px solid #eee;
    padding: 2px;
    border-radius: 2px;
}
.close-edition i{
    font-size: 25px;
}
.close-edition:hover{
    cursor: pointer;
    background-color: #f7f4f4;
    border: 1px solid #f7f4f4;
}
.edit-inline-field:hover{
    cursor: pointer;
    border: 1px solid #ced4da;
}
.edit-inline-field.disabled:hover{
    cursor: default;
    border: 0;
}
.edit-inline-field.enabled:hover{
    cursor: none;
    border: 0;
}
.job-details .form-control:disabled,
.job-details .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
    border: 0;
    padding-left: 0;
}
.job-details label{
    margin-bottom: 0px;
}

.table.job-table > :not(caption) > * > * {
    padding: 0 10px;
}
.table.job-table > :not(caption) .edit-inline-field {
    padding: 0 10px;
}

#marqueId {
   width: 200px;
   z-index: 1000;
}
#planoDesignationId {
   width: 200px;
   z-index: 1000;
}

#niveau3Id {
   width: 200px;
   z-index: 1000;
}

#niveau4Id {
   width: 200px;
   z-index: 1000;
}


.table.job-table th {
    font-weight: 600;
    white-space: pre-line !important;
}

.job-table tr td.odd {
    background-color:#f6f6f6;
}

.job-table tr.tab-bgcolor {
    background-color: rgb(192,192,192) !important;
}

.job-table tr td.tab-bgcolor {
    background-color: rgb(192,192,192) !important;
}

.edit-inline-field.form-control{
    min-width: 130px;
    min-height: 35px;
}

.row.error-box {
    background-color: #f7040430;
    border-radius: 10px;
    padding: 15px 10px 15px 20px;
    margin: 0 3px 15px 0;
}

.row.success-box {
    background-color: #04f73530;
    border-radius: 10px;
    padding: 15px 10px 15px 20px;
    margin: 0 3px 15px 0;
}

.error-box-title {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
}

.field-contains-error{
    border: 2px solid red !important;
    border-radius: 10px;
    padding: 5px;
}

.upload-product-pic-label {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: #556ee6;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
}

.upload-product-pic-label i {
    font-size: 18px;
}

.remove-added-product-pic-btn i{
    font-size: 18px;
    margin-right: 10px;
}

.carousel-indicators{
    bottom: 10px !important;
}
.tableContainer{
    overflow: auto;
}